import { SINGER } from "./singers";
import { TAGS } from "./tags";
export const qawwali = [
  {
    link: "https://youtu.be/INs3aYu4Q9g?si=illQ6Qgvr1RqfB_m",
    title: "Ye jo halka halka Surur hai",
    id: 1,
    videoCode: "INs3aYu4Q9g",
    singer: SINGER.N_F_A_K,
  },
  {
    link: "https://www.youtube.com/watch?v=UIPXHsUXVH0",
    title: "Ye jo halka halka Surur hai || OSA",
    id: 2,
    videoCode: "UIPXHsUXVH0",
    singer: SINGER.N_F_A_K,
  },

  {
    link: "https://www.youtube.com/watch?v=wAk-Wg9UK0o",
    title: "Mere Banne ki Baat na Poochho ",
    id: 3,
    videoCode: "wAk-Wg9UK0o",
    singer: SINGER.USTAD_FARID_AYAZ,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=lsqsggtTZfs",
    title: "Kali Kali Zulfon Ke Phande Na Dalo",
    id: 4,
    videoCode: "lsqsggtTZfs",
    singer: SINGER.N_F_A_K,
  },
  {
    link: "https://www.youtube.com/watch?v=QuNyMxLlVig",
    title: "Sochta Hoon Ke Woh Kitne Masoom",
    id: 5,
    videoCode: "lsqsggtTZfs",
    singer: SINGER.N_F_A_K,
  },
  {
    link: "https://www.youtube.com/watch?v=UIPXHsUXVH0",
    title: "Ye Jo Halka Halka Saroor Hai",
    id: 6,
    videoCode: "UIPXHsUXVH0",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=f3dnF-GmscM",
    title: "Chaap Tilak Sab Cheen",
    id: 7,
    videoCode: "f3dnF-GmscM",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=eYSaHXXFIBU",
    title: "Sanson Ki Mala Pe Simron Mein",
    id: 8,
    videoCode: "eYSaHXXFIBU",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=d7AqPH-LgmI",
    title: "Sansoon Ki Mala Pe SimronPee Ka Naam || 1985 ||UK",
    id: 9,
    videoCode: "",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=BIOgR38G2Zs",
    title: "Aankh Uthi Mohabbat Ne Angrai Li",
    id: 10,
    videoCode: "BIOgR38G2Zs",
    singer: SINGER.N_F_A_K,
  },
  {
    link: "https://www.youtube.com/watch?v=PEP_d2aZOng",
    title: "Hai Kahan Ka Irada Sanam",
    id: 11,
    videoCode: "PEP_d2aZOng",
    singer: SINGER.N_F_A_K,
  },
  {
    link: "https://www.youtube.com/watch?v=PEP_d2aZOng",
    title: "Hai Kahan Ka Irada Sanam",
    id: 12,
    videoCode: "PEP_d2aZOng",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=xs7tN0A7RC8",
    title: "Tumhein Dillagi Bhool Jani Paray Gi",
    id: 13,
    videoCode: "xs7tN0A7RC8",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=gY01irEl8Eo",
    title: "MERE RASHKE QAMAR ",
    id: 14,
    videoCode: "gY01irEl8Eo",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=DKpzCm_nxTo",
    title: "Ankh Uthi Mohabbat Ne Angrai Li",
    id: 15,
    videoCode: "DKpzCm_nxTo",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=zXdk8uoSFMI",
    title: "Husan Walo Se Allah Bachaye",
    id: 16,
    videoCode: "zXdk8uoSFMI",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=LN6kt-YxszA",
    title: "Tum Ek Gorakh Dhanda Ho",
    id: 17,
    videoCode: "LN6kt-YxszA",
    singer: SINGER.N_F_A_K,
    tags: [TAGS.SUFI],
  },
  {
    link: "https://www.youtube.com/watch?v=NoHCQEUja4Y",
    title: "Har Lehza Ba Shakal Aan",
    id: 18,
    videoCode: "NoHCQEUja4Y",
    singer: `${SINGER.USTAD_FARID_AYAZ}, ${SINGER.ABU_MUHAMMAD}`,
    tags: [TAGS.KABIR],
  },
  {
    link: "https://www.youtube.com/watch?v=GHAMyHerv90",
    title: "Kanhaiya, Yaad Hai Kuchh Bhi Hamaari",
    id: 19,
    videoCode: "GHAMyHerv90",
    singer: `${SINGER.USTAD_FARID_AYAZ}, ${SINGER.ABU_MUHAMMAD}`,
    tags: [TAGS.KABIR],
  },
  {
    link: "https://www.youtube.com/watch?v=q89NdfH-P8Q",
    title: "Kehna Ghalat Ghalat To Chupaana Sehi Sehi",
    id: 19,
    videoCode: "q89NdfH-P8Q",
    singer: `${SINGER.N_F_A_K}`,
    tags: [],
  },
];

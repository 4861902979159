import { SINGER } from "./singers";

export const gazal = [
  {
    id: 1,
    link: "https://www.youtube.com/watch?v=ZK3tVnoYqHM",
    title: "Ye Kaghzi Phhol Jese Chehre",
    singer: SINGER.MEHANDI_HASAN,
  },

  {
    id: 2,
    link: "https://www.youtube.com/watch?v=EpO1fKdHXg0",
    title: "Wo Mujhe Chhodkar Ghair Ka Ho Gaya ",
    singer: SINGER.CHANDAN_DAS,
  },
  {
    id: 3,
    link: "https://www.youtube.com/watch?v=89yCZNuHlzI",
    title: "Jaane Wale",
    singer: SINGER.GULAM_ALI,
  },
];

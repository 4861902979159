export const SINGER = {
  MEHANDI_HASAN: "Mehandi Hasan",
  RAHAT_F_A_K: "Rahat Fateh Ali Khan",
  ARJIT_SINGH: "Arijit Singh",
  GULZAR: "Gulzar",
  NIHARIKA_SINGH_DESHPANDE: "Nihira Joshi Deshpande",
  USTAD_HUSSAIN_BAKSH_GULLO: "Ustad Hussain Baksh Gullo",
  SHAFQAT_AMANAT_ALI: "Shafqat Amanat Ali",
  HADIQA_KIANI: "Hadiqa Kiani",
  CHANDAN_DAS: "Chandan Das",
  LATA_MANGESHKAR: "Lata Mangeshkar",
  Md_RAFI: "Mohammed Rafi",
  N_F_A_K: "Ustad Nusrat Fateh Ali Khan",
  FAHEEM_ABDULLAH: "Faheem Abdullah",
  RAUHAN_MALIK: "Rauhan Malik",
  USTAD_FARID_AYAZ: "Ustad Farid Ayaz	",
  MUSSARAT_NAZEER: "Mussarat Nazeer",
  DINESH_LAL_YADAV: "Dinesh Lal Yadav",
  GULAM_ALI: "Gulam Ali",
  ABIDA_PARVEEN: "Abida Parveen",
  KISHOR_KUMAR: "Kishor Kumar",
  MAITHALI_THAKUR: "Maithali Thakur",
  MALINI_AWASTHI: "Malini Awasthi",
  JAGJIT_SINGH: "Jagjit Singh",
  CHITRA_SINGH: "Chitra Singh",
  ABU_MUHAMMAD: "Abu Muhammad",
  USTAD_VILAYAT_KHAN: "Ustad Vilayat Khan",
  USTAD_BISMILLAH_KHAN: "Ustad Bismillah Khan",
};

import { SINGER } from "./singers";

export const bhajan = [
  {
    link: "https://www.youtube.com/watch?v=d7AqPH-LgmI",
    title: "Mohe Panghat Pe Nandlal",
    id: 1,
    videoCode: "",
    singer: `${SINGER.USTAD_VILAYAT_KHAN},${SINGER.USTAD_BISMILLAH_KHAN}`,
  },
];
